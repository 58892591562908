import type { I18nLocale } from "@/modules/i18n/types"
import type { NetlinkingBlock } from "@/modules/jobSearchPrefiltered/types"

export const PREFILTERED_BASENAME = "job-search"

export const GET_SEARCH_PARAMS_FROM_SLUG_ENDPOINT =
  "/jobteaser.slugdictionary.prefilter_job_search.v1alpha1/PrefilterJobSearchService/GetSearchParamsFromSlug"

export const GET_CONTENT_FROM_SLUG_ENDPOINT =
  "/jobteaser.slugdictionary.prefilter_job_search.v2/PrefilterJobSearchService/GetContentFromSlug"

export const GET_ACTIVE_SLUGS_ENDPOINT =
  "/jobteaser.slugdictionary.prefilter_job_search.v2/PrefilterJobSearchService/GetActiveSlugs"

export const LIST_ITEMS_PER_PAGE = 24

export const PREFILTERED_JOB_SEARCH_ENABLED_LOCALES: I18nLocale[] = ["de", "es", "fr", "nl"]

export const TITLE_TRANSLATION_KEY_PER_TYPE: Record<
  NetlinkingBlock["type"],
  `${"job_search_prefiltered.content"}.${string}`
> = {
  NETLINKING_BLOCK_TYPE_CONTRACT: `job_search_prefiltered.content.netlinkingTitlePerCity`,
  NETLINKING_BLOCK_TYPE_FUNCTION: `job_search_prefiltered.content.netlinkingTitlePerCityAndFunction`,
  NETLINKING_BLOCK_TYPE_INDUSTRY: `job_search_prefiltered.content.netlinkingTitlePerCityAndIndustry`,
  NETLINKING_BLOCK_TYPE_UNSPECIFIED: `job_search_prefiltered.content.netlinkingTitlePerCity`,
}

export const MAX_DISPLAYED_NETLINKING_TAGS = 5
